<template>
  <div>
    <v-simple-table dense style="width:100%;">
      <template v-slot:default>
        <thead>
          <tr>
            <th>#</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit Price</th>
			<th>Total</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in poItems"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
				<v-text-field
					v-model="item.item_description"
					hide-details
				></v-text-field>
            </td>
            <td>
				<v-text-field
					v-model="item.quantity"
					hide-details
					type="number"
					min="0"
				/>
            </td>
            <td>
              <v-text-field
					v-model="item.unit_cost"
					hide-details
					type="number"
					min="0"
				/>
            </td>
			<td>
				<v-text-field
					:value="formatTotalAmount(item.total_amount)"
					hide-details
					readonly
				/>
            </td>
            <td>
              <v-btn icon>
                <v-icon small color="red" @click="deletePOItem(index)">delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <b>Total Amount</b>
            </td>
            <td>&nbsp;</td>
            <td class="text-right">
            </td>
            <td class="text-right">
              <b>{{formatTotal}}</b>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
		<v-card-actions>
			<v-btn right dark color="primary" style="padding:10px; margin-left:10px;" @click="addDetail">
				<v-icon>mdi-plus</v-icon>
				<span>Add new Row</span>
				</v-btn>
			<upload-btn
				@file-update="importExcel"
				title="Import Spreadsheet">
			</upload-btn>
		</v-card-actions>
  </div>
</template>
<script>
import numeral from 'numeral';
import UploadButton from '@/components/partials/AppImportButton';
import xlsx from 'xlsx';
import { format } from 'date-fns';

export default {
	components: {
		'upload-btn': UploadButton,
	},
	props: ['poItemsProp'],
	data() {
		return {
			headers: [],
			details: [],
			page: 1,
			pages: [],
			totalPage: 1,
			dataCoas: [],
			nextPageCoas: '',
			rowPage: process.env.VUE_APP_TRX_DETAIL_ROW,
			poItems: [
				{
					po_number: this.po_number,
					po_date: this.getCurrentDate(),
					item_description: '',
					quantity: 0,
					unit_cost: numeral(0).format('0,0.00'), 
					total_amount: numeral(0).format('0,0.00')
				}
			],
			total: numeral(0).format('0,0.00')
		};
	},
	mounted () {
		console.log(this.poItemsProp);
		this.poItems = this.poItemsProp;
	},
	watch: {
		poItems: {
			handler() {
				// console.log(val);
				this.computePO();
				this.$emit('changedPODetails', this.poItems);
			},
			deep: true
		}
	},
	computed: {
		formatTotal () {
			return numeral(this.total).format('0,0.00');
		}
	},
	methods: {
		getCurrentDate() {
			return format(new Date(), 'yyyy-MM-dd');
		},
		importExcel (e) {
			const self = this;
			// const app = this.$data;
			const files = e.target.files, f = files[0];
			const reader = new FileReader();
			reader.onload = function(e) {
				const data = new Uint8Array(e.target.result);
				const workbook = xlsx.read(data, {type: 'array'});
				let sheetName = workbook.SheetNames[0];
				let worksheet = workbook.Sheets[sheetName];
				const excelResult = xlsx.utils.sheet_to_json(worksheet);
				self.poItems = excelResult;
			};
			reader.readAsArrayBuffer(f);
		},
		formatTotalAmount (amount) {
			return numeral(amount).format('0,0.00');
		},
		deletePOItem (index) {
			this.poItems.splice(index, 1);
		},
		computePO () {
			this.total = 0;
			this.poItems.forEach(function(e){
				e.total_amount = e.unit_cost * e.quantity;
			});
			this.computeTotalAmount();
		},
		computeTotalAmount () {
			const self = this;
			this.poItems.forEach(function(e){
				self.total += e.total_amount;
			});
		},
		addDetail() {
			this.poItems.push({
				po_number: this.po_number,
				po_date: this.getCurrentDate(),
				item_description: '',
				quantity: 0,
				unit_cost: numeral(0).format('0,0.00'), 
				total_amount: numeral(0).format('0,0.00')
			});
		}
	}
};
</script>
<style scoped>
  .v-autocomplete {
    font-size: 14px
  }
  .amount {
    font-size: 14px
  }
</style>
